* {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-family: 'Lato', sans-serif;
}

h2 {
  font-family: 'Lato', sans-serif;
}

h3 {
  font-family: 'Lato', sans-serif;
}

h4 {
  font-family: 'Lato', sans-serif;
}

h5 {
  font-family: 'Lato', sans-serif;
}

h6 {
  font-family: 'Lato', sans-serif;
}

.montserrat{
  font-family: 'Montserrat', sans-serif;
}

.text-default {
  font-family: 'Lato', sans-serif;
  color: #89764b;
  font-weight: 600;
}

body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.l-size {
  height: 50px !important;
}

.icon_menu span {
  display: block;
  background-color: #89764b;
  width: 100%;
  height: 4px;
  margin-top: -2px;
  position: absolute;
  left: 0;
  top: 50%;
  transition: all 0.2s ease;
}

.icon_menu {
  height: 32px;
  width: 43px;
  margin: 3%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.icon_menu:before,
.icon_menu:after {
  content: '';
  display: block;
  background-color: #89764b;
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  transform-origin: center center;
  transform: rotate(0deg);
  transition: all 0.2s ease;
}

.icon_menu:before {
  top: 2px;
  margin-top: -2px;
}

.icon_menu:after {
  bottom: 2px;
  margin-bottom: -2px;
}

.icon_menu[aria-expanded="true"] span {
  display: none;
}

.icon_menu[aria-expanded="true"]:before {
  top: 50%;
  transform: rotate(45deg);
}

.icon_menu[aria-expanded="true"]:after {
  bottom: 50%;
  transform: rotate(-45deg);
}


.swiper {
  width: 100%;
  height: 100%;

}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}



.img-caveau {
  position: relative;
}

.img-caveau::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  opacity: 0.6;
  z-index: 1;
}




.background-clienti,
.background-collaboratori {
  position: relative;
}

.background-clienti::before,
.background-collaboratori::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 25px;
  background-color: rgb(255, 255, 255);
  opacity: 0.8;
}

.background-clienti .card-body,
.background-collaboratori .card-body {
  position: relative;
  z-index: 2;
  border-radius: 25px;
}

.bg-default {
  background-color: #89764b;
}

.swiper-pagination-bullet {
  background: #ffffff!important;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1)!important;
  border: 1px solid rgb(194, 194, 194)!important;
}

.swiper-pagination-bullet-active {
  background: #89764b!important;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1)!important;
  border: 1px solid rgb(194, 194, 194)!important;
}

.swiper-button-prev {
  color: #89764b;

}

.swiper-button-next {
  color: #89764b;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: medium;
  font-weight: bolder;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1
}

/*  
  .bg-marmo:after{
    content:"";
    position:fixed; 
    top:0;
    height:100vh;
    left:0;
    right:0;
    z-index:-1;
    background: url(./components/assets/img/home/bgmarmo.webp) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
 */
.custom-bg-icon {
  border-radius: 150px 150px 150px 150px;
}

#goldPrice {
  transition: top 1s;
}

#mainNav {
  transition: top 1s;
  top: 28px;
}

.bg-fixing-price-lg {
  background: rgb(137, 118, 75);
  /* background: linear-gradient(90deg, rgba(248, 224, 95, 1) 0%, rgba(248, 223, 94, 1) 25%, rgba(210, 149, 32, 1) 75%); */
}

@media (min-width: 992px) {
  #mainNav {
    transition: top 0s;
    top: 0px !important;
  }
}

@media (min-width: 768px) {
  .nav-link {
    font-size: 0.76rem;
  }
}

@media (min-width: 1200px) {
  .nav-link {
    font-size: medium;
  }
}

.fa-phone-volume {
  rotate: -150deg;
}

.expanded {
  overflow: visible;
}

.collapsed {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collapsed1 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 768px) {
  .collapsed1 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.collapsed2 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rounded-25 {
  border-radius: 25px;
}

.modal {
  --bs-modal-width: 1050px !important;
  position: fixed;
  --bs-modal-header-border-color: #89764b;
  --bs-modal-header-border-width: 1px;
}

/* contact form */

.contact-form {
  border-radius: 0.5vh;
  background-color: rgb(255, 255, 255);
  padding: 20px;
}

/* contact form */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

#prz {
  transform: rotate(4deg);
  transition: 1000ms;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

/* input:checked + .slider {
  background-color: #89764b;
  ;
}

input:focus + .slider {
  box-shadow: 0 0 1px #89764b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
} */
.modal-body {
  height: 60% !important;
  width: 100% !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}



.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1.5px solid red;
}


/* popup */

/* contact form */
/* The switch - the box around the slider */
.switch-popup {
  position: relative;
  display: inline-block;
  width: 48.5px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch-popup input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider-popup {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

#prz {
  transform: rotate(4deg);
  transition: 1000ms;
}

.slider-popup:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 1.5px;
  bottom: 1.32px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

/* input:checked + .slider-popup {
  background-color: #89764b;
  ;
}

input:focus + .slider-popup {
  box-shadow: 0 0 1px #89764b;
}

input:checked + .slider-popup:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
} */
.modal-body {
  height: 60% !important;
  width: 100% !important;
}

/* Rounded sliders */
.slider-popup.round {
  border-radius: 34px;
}

.slider-popup.round:before {
  border-radius: 50%;
}



.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1.5px solid red;
}

.btn-default:disabled {
  background-color: #89764b;
  color: #fff;
  width: auto !important;
  /* border-radius: 50px; */
  border: none;
  font-weight: 700;
}

.btn-default {
  background-color: #89764b;
  color: #fff;
  width: auto !important;
  /* border-radius: 50px; */
  border: none;
  font-weight: 700;
}

.btn-default:focus,
.btn-default:hover {
  background-color: #89764b;
  color: #fff;
  transition: 0.5s;
  transform: scale(1.05);
  width: auto !important;
  /* border-radius: 50px 50px 50px 50px; */
  font-weight: 700;
}

.btn-default:active {
  background-color: #89764b;
  color: #fff;
  transition: 0.5s;
  transform: scale(0.99);
  width: auto !important;
  /* border-radius: 50px 50px 50px 50px; */
  border: none;
  font-weight: 700;
}

.btn-default-cat {
  width: 100% !important;
  background-color: #89764b;
  color: #fff;
  /* border-radius: 50px; */
  border: none;
  font-weight: 700;
}

.btn-default-cat:focus,
.btn-default-cat:hover {
  background-color: #89764b;
  color: #fff;
  transition: 0.5s;
  transform: scale(1.05);
  width: 100% !important;
  /* border-radius: 50px 50px 50px 50px; */
  font-weight: 700;
}

.btn-default-cat:active {
  background-color: #89764b;
  color: #fff;
  transition: 0.5s;
  transform: scale(0.99);
  width: 100% !important;
  /* border-radius: 50px 50px 50px 50px; */
  border: none;
  font-weight: 700;
}


#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}

.text-link {
  color: darkblue;
}

.text-link::after {
  color: darkblue;
}

.text-link:hover {
  color: darkblue;
}


.iframe-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100%) */
  height: 0;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iframe-responsive-size {
  height: 206px;
}

@media (min-width: 768px) {
  .iframe-responsive-size {
    height: 270px;
    width: 500px;
  }
}

@media (min-width: 992px) {
  .iframe-responsive-size {
    height: 320px;
    width: 600px;
  }
}

@media (min-width: 1200px) {
  .iframe-responsive-size {
    height: 490px;
    width: 900px;
  }
}

.large-header {
  position: relative;
  width: 100%;
  background: #333;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

/* .bg-oro:after{
  content:"";
  position:fixed; 
  top:0;
  height:100vh;
  left:0;
  right:0;
  z-index:-1;
  background: url(./components/assets/img/landingCIG/bg-investire-noi.webp) center left;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
 */

.piani-rounded {
  border-radius: 25px 25px 25px 25px;
}

.button-rounded {
  border-radius: 10px 10px 10px 10px;
}

.btn-social {
  height: 2.5rem;
  width: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100%;
}

/* radio button check box */

.form-check-input-custom[type=checkbox] {
  border-radius: 0.25em;
}

.form-check-input-custom {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

.form-check-input-custom:checked {
  background-color: #89764b;
  border-color: #89764b;
}

.form-check-input-custom:checked[type=checkbox] {
  --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e);
}

/* radio button check box */


/* editor style */
.ql-container.ql-snow {
  height: 308px !important;
}

.ql-editor {
  overflow-y: hidden;
}

/* editor style */

/* search bar */

.inputContainer {
  position: relative;
}

.inputContainer input {
  padding-right: 30px;
}

.searchIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* search bar */

.first-button {
  z-index: 4;
}

.custom-overflow {
  overflow: visible !important;
}

.show1 {
  transform: translateX(-20%);
  transition: transform 1s;
  z-index: 1;
}

.show2 {
  transform: translateX(-20%);
  transition: transform 1s;
  z-index: 2;
}

.show3 {
  transform: translateX(-20%);
  transition: transform 1s;
  z-index: 3;
}

.hide1 {
  transform: translateX(400%);
  transition: transform 1s;
  z-index: 1;
}

.hide2 {
  transform: translateX(250%);
  transition: transform 1s;
  z-index: 2;
}

.hide3 {
  transform: translateX(125%);
  transition: transform 1s;
  z-index: 3;
}

.bg-facebook {
  background-color: #3B5999 !important;
}

.bg-linkedin {
  background-color: #0E66A7 !important;
}

.bg-instagram {
  background-color: #E32F6C !important;
}

.bg-youtube {
  background-color: #DE463B !important;
}

.bg-whatsapp {
  background-color: #25D366 !important;
}

.rounded-circle1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .rounded-circle1 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

/* share button */

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #d6d6d6;
  border-radius: 10px !important;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}


.icon-container {
  display: inline-block;
  width: 50px;
  /* Imposta la larghezza del quadrato */
  height: 50px;
  /* Imposta l'altezza del quadrato */
  text-align: center;
  /* Centra l'icona orizzontalmente */
  line-height: 50px;
  /* Centra l'icona verticalmente */
  border-radius: 50%;
  background-color: #89764b;
}

@media (min-width: 1200px) {
  .icon-left {
    float: left;
    margin-right: 10px;
  }
}

.article-container {
  display: flex;
  /* Utilizza un layout flessibile per posizionare i contenuti */
}

.text-container {
  flex: 1;
  /* Il contenitore del testo occupa tutto lo spazio rimanente */
}

.image-container {
  position: relative;
  /* Imposta il posizionamento relativo per consentire il posizionamento dell'immagine */
  float: right;
  /* Posiziona l'immagine a destra */
  margin-left: 20px;
  /* Aggiungi un margine a sinistra per separare l'immagine dal testo */
  width: 300px;
  /* Imposta la larghezza desiderata per l'immagine */
}

.image-container img {
  max-width: 100%;
  /* Assicurati che l'immagine si adatti al contenitore */
  height: auto;
  /* Imposta l'altezza dell'immagine in base alla larghezza */
  float: left;
  /* Posiziona l'immagine a sinistra all'interno del contenitore */
  shape-outside: circle();
  /* Opzionale: se desideri dare una forma specifica all'immagine */
  margin: 0 20px 20px 0;
  /* Opzionale: aggiungi margini per distanziare l'immagine dal testo */
}

/* popup */

/* contact form */
/* The switch - the box around the slider */
.switch-popup {
  position: relative;
  display: inline-block;
  width: 48.5px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch-popup input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* overlay text */
.text-overlay {
  position: absolute;
  top: 47vw;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-shadow: 8px 5px 10px black;
  letter-spacing: 0em;
  transition: letter-spacing 0.5 ease-in-out;
  animation: stretch 2s ease-in-out forwards;
  font-size: 6vw;
  line-height: 1.1em;
  /* animation-delay: 0.2s; */
}

.pop-up-container {
  position: relative;
}

.text-shadow-none {
  text-shadow: none !important;
}

.text-overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes stretch {
  from {
    letter-spacing: 0.15em;
  }

  to {
    letter-spacing: 0em;
  }
}

@media (min-width: 992px) {

  .margin-left-custom {
    margin-left: 59.5%;
  }

}

@media (min-width: 1200px) {

  .margin-left-custom {
    margin-left: 44%;
  }

}



.mySwiper {
  margin-top: 0px
}

.current-location-btn {
  background-color: #fff;
  border: none;
  color: #333;
  font-size: 14px;
  padding: 8px 0px;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 40px;
}

.sede-btn {
  background-color: #fff;
  border: none;
  color: #333;
  font-size: 14px;
  padding: 10px 0px;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 40px;
  top: 120px !important;
}

.custom-margin-top {
  margin-top: 6.2rem;
}

@media (min-width: 576px) {
  .custom-margin-top {
    margin-top: 6.3rem;
  }
}

@media (min-width: 768px) {
  .custom-margin-top {
    margin-top: 7rem;
  }
}

@media (min-width: 992px) {
  .custom-margin-top {
    margin-top: 5.6rem;
  }
}

@media (min-width: 1200px) {
  .custom-margin-top {
    margin-top: 6rem;
  }
}


/* contact form */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border: 0.1px solid rgba(0, 0, 0, 0.195);
  -webkit-transition: .4s;
  transition: .4s;
}

#prz {
  transform: rotate(4deg);
  transition: 1000ms;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 1.1px;
  bottom: 1.1px;
  background-color: white;
  border: 0.1px solid rgba(0, 0, 0, 0.195);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #89764b;
  ;
}

input:focus+.slider {
  box-shadow: 0 0 1px #89764b;
}

input:checked+.slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.modal-body {
  height: 60% !important;
  width: 100% !important;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Rounded sliders */


.close-button {
  position: absolute;
  top: 0px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.close-icon {
  font-size: 20px;
  color: #000;
}

.icon360 {
  position: relative;
  top: -15px;
  transform: none;
  width: 80px;
  height: 60px;
  opacity: 50%;
  margin-right: 0px;
}

@media (min-width: 768px) {
  .icon360 {
    position: relative;
    top: -3%;
    transform: none;
    width: 100px;
    height: 0px;
    opacity: 50%;
    margin-right: 11px;
  }
}

.sc-aXZVg {
  max-width: 100% !important;
  height: auto;
}

.custom-logo-size {
  width: 15rem;
  margin-inline: auto;
}

@media (min-width: 768px) {
  .custom-logo-size {
    width: 20rem;
    margin-inline: auto;
  }
}

@media (min-width: 576px) {
  .custom-rounded-home {
    border-radius: .375rem;
  }
}

.inputContainer input {
  padding-right: 0px;
}

.fs-custom-card {
  font-size: 1rem;
}

.fs-5-psc {
  font-size: 1rem !important;
}

@media (min-width: 1200px) {
  .fs-5-psc {
    font-size: 1rem !important;
  }
}

@media (min-width: 1400px) {
  .fs-5-psc {
    font-size: 1.25rem !important;
  }
}

@keyframes shine {
  0% {
    background-position: left;
  }

  100% {
    background-position: right;
  }
}

.border-gold {
  position: relative;
  background: linear-gradient(90deg, rgba(186, 148, 62, 1) 0%, rgba(236, 172, 32, 1) 20%, rgba(186, 148, 62, 1) 39%, rgba(249, 244, 180, 1) 50%, rgba(186, 148, 62, 1) 60%, rgba(236, 172, 32, 1) 80%, rgba(186, 148, 62, 1) 100%);
  padding: 3px;
  animation: shine 3s infinite;
  background-size: 300%;
  background-position: left;
}

.border-gold-light {
  position: relative;
  background: linear-gradient(90deg, rgba(186, 148, 62, 1) 0%, rgba(236, 172, 32, 1) 20%, rgba(186, 148, 62, 1) 39%, rgba(249, 244, 180, 1) 50%, rgba(186, 148, 62, 1) 60%, rgba(236, 172, 32, 1) 80%, rgba(186, 148, 62, 1) 100%);
  padding: 1px;
  animation: shine 3s infinite;
  background-size: 300%;
  background-position: left;
}

/* Story */
.border-insta-effect {
  border: 3px solid white !important;
}

.story-item-active .image {
  padding: 3px;
  border-radius: 1000px;
  background: rgb(100, 100, 100, 0.5);
}

.story-item-disable .image {
  padding: 3px;
  border-radius: 1000px;
  background: linear-gradient(90deg, rgba(186, 148, 62, 1) 0%, rgba(236, 172, 32, 1) 20%, rgba(186, 148, 62, 1) 39%, rgba(249, 244, 180, 1) 50%, rgba(186, 148, 62, 1) 60%, rgba(236, 172, 32, 1) 80%, rgba(186, 148, 62, 1) 100%);
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite;
  background-size: 300%;
  background-position: left;
}

.story-item-default .image img {
  border-radius: 1000px;
  width: 100px;
  height: 100px;
  display: block;
}

@media only screen and (max-width: 735px) {
  .story-item-default .image img {
    width: 80px;
    height: 80px;
  }
}

.story-item-default .title {
  padding-top: 15px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 735px) {
  .story-item-default .title {
    font-size: 12px;
    padding-top: 8px;
    font-weight: 400;
    width: 65px;
  }
}

.story-item-default {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
}

@media only screen and (max-width: 735px) {
  .story-item-default {
    padding: 0px 5px;
  }
}

.stories {
  display: flex;
  justify-content: start;
  padding: 0 24px;
  overflow-x: scroll;
  position: relative;
  margin-bottom: 44px;
}

.stories::-webkit-scrollbar {
  width: 0;
}

@media only screen and (max-width: 735px) {
  .stories {
    padding: 0;
    margin-bottom: 21px;
  }
}

.slider-button-next {
  position: sticky;
  right: 0;
  cursor: pointer;
}

.background-newsletter {
  position: relative;
}


.background-sfumato-bottom {
  background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
}

/* airplane */

.btn-airplane{
  color: #FFF;
  background-color: #89764b;
  border: 1px solid #ccb167;
  border-radius:2px;
  width:100%;
  margin-top:10px;
  min-height:48px;
  line-height: 48px;
  text-align: center;
  font-size: 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  overflow:hidden;
}
.btn-airplane .text{
  position:relative;
}
.btn-airplane .send{
  display:none;
}

.btn-airplane .sended{
  display:none;
}

.btn-airplane .airplane-icon{
  /* font-size:20px; */
  color: #FFF;
  z-index:1000;
  margin-left:10px;
  transition:all 0.2s;
}
.btn-airplane:not(.send):hover .airplane-icon{
  transform:rotateZ(-10deg);
}
.btn-airplane.send .text{
  transition:all 0.5s;
  display:none;
}

.btn-airplane.sended .send{
  transition:all 0.5s;
  display:none;
}

.btn-airplane.sended .text{
  transition:all 0.5s;
  display:none;
}

.btn-airplane.sended .sended{
  display:block;
}

.btn-airplane.sended .icon{
  display:none;
}

.btn-airplane.send .send{
  position: absolute; 
  margin-left: -3rem!important;
  opacity: 1;
  display: inline-block;
  animation: blink 2s infinite;
}
.btn-airplane.send .icon{
  animation:flysend 2s;
  position:relative;
  left:1000px;
}
.btn-airplane.send .airplane-icon{
  animation:flyspin 2s;
}
@keyframes flyspin{
  0%{transform:rotateZ(-20deg);}
  75%{transform:rotateZ(40deg);}
  100%{transform:rotateZ(40deg);}
}
@keyframes flysend{
  0%{left:20px;}
  75%{left:-100px;}
  100%{left: 100px;}
}

@keyframes blink{
  0%{opacity:0;}
  50%{opacity:1;}
  100%{opacity:0;}
}

.centered-div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* This ensures that the div takes up at least the full viewport height */
}

.text-animate-gold {
  position: relative;
  background: linear-gradient(90deg, rgba(186, 148, 62, 1) 0%, rgba(236, 172, 32, 1) 20%, rgba(186, 148, 62, 1) 39%, rgba(249, 244, 180, 1) 50%, rgba(186, 148, 62, 1) 60%, rgba(236, 172, 32, 1) 80%, rgba(186, 148, 62, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite;
  background-size: 300%;
  background-position: left;
}

.fw-900{
  font-weight: 900;
}

#prodottiServizi .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, 0px);
}

/* -----------------------------------------------------------start landing fondazione veronesi------------------------------------------------------------ */

.fullscreen-video-fv {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.fullscreen-video-fv video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay-content-fv {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
}

.overlay-intro-fv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.overlay-bg-fv {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.558);
  text-align: center;
}

.overlay-logo-fv {
  position: absolute;
  top: 2%;
  right: 2%;
  width: 50%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    letter-spacing: 5px;
  }
  to {
    opacity: 1;
    letter-spacing: 0;
  }
}

@keyframes fadeImgFV {
  0% {
      opacity: 0;
    }
    50% {
      opacity: 0; 
    }
    100% {
      opacity: 1; 
    }
  }

.opening-animation-fv {
  animation: fadeIn 3s ease; 
  font-size: 1.2rem;
}

.opening-image-fv{
  animation: fadeImgFV 2s ease;
  margin-left: 5%;
  margin-top: 10%;
  width: 90%;
}

@keyframes bounceUpDown {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.arrow-icon-fv {
  color: #fff; 
  cursor: pointer;
  animation: bounceUpDown 2s infinite;
}

.arrow-icon-fv:hover {
  color: #ffffff!important; 
  transform: scale(1.1)!important;
  animation: none !important;
}

.h1-fv{
  font-size: 1.9rem;
}

@media (min-width: 768px) {

  .overlay-logo-fv {
      position: absolute;
      top: 2%;
      left: 2%;
      width: 40%;
    }

    .opening-animation-fv {
      animation: fadeIn 3s ease; 
      font-size: 1.5rem;
    }

    .overlay-content-fv {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      text-align: start;
    }

    .h1-fv{
      font-size: 3.3rem!important;
      margin-left: 10%;
    }

}

@media (min-width: 1200px) {

  .overlay-logo-fv {
      position: absolute;
      top: 2%;
      left: 2%;
      width: 25%;
    }

    .opening-animation-fv {
      animation: fadeIn 3s ease; 
      font-size: 1.2rem;
    }

    .h1-fv{
      font-size: 4rem!important;
      margin-left: 15%;
    }

}

@media (min-width: 1400px) {

  .overlay-logo-fv {
      position: absolute;
      top: 2%;
      left: 2%;
      width: 20%;
    }

    .opening-animation-fv {
      animation: fadeIn 3s ease; 
      font-size: 1.5rem;
    }

}

.font-great-vibes{
  font-family: 'Great Vibes', cursive;
  color: #89764b;
  font-size: 4.2rem;
  line-height: 4.5rem;
}

@media (min-width: 768px) {
  .font-great-vibes{
    font-family: 'Great Vibes', cursive;
    color: #89764b;
    font-size: 6rem;
    line-height: 4.5rem;
  }
}


.ricercatrice-fv{
  position: relative;
  top: -4vw;
}

.outline-text {
  text-shadow: -2px -2px 7px rgba(0, 0, 0, 0.5), 2px 2px 7px rgba(0, 0, 0, 0.5); /* Create a shadow effect */
}

/* -----------------------------------------------------------end landing fondazione veronesi------------------------------------------------------------ */

/* -----------------------------------------------------------start landing oro da investimento------------------------------------------------------------ */

.bg-landing-care{
  background-color: #191919;
}

.overlay-content-odi {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
}

.overlay-logo-odi {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%); 
}

.p-odi{
  margin-left: 0%;
}

@media (min-width: 768px) {

  .overlay-logo-odi {
      position: absolute;
      top: 10%;
      left: 25%;
      width: 40%;
      transform: translate(-50%, -50%); 
    }
    .overlay-content-odi {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      text-align: start;
    }

    .p-odi{
      margin-left: 10%;
    }

}

@media (min-width: 1200px) {

  .overlay-logo-odi {
      position: absolute;
      top: 8%;
      left: 15%;
      width: 25%;
      transform: translate(-50%, -50%); 
    }
    .p-odi{
      margin-left: 15%;
    }

}

@media (min-width: 1400px) {

  .overlay-logo-odi {
      position: absolute;
      top: 12%;
      left: 14%;
      width: 20%;
      transform: translate(-50%, -50%); 
    }

}

@keyframes bounce {
  0% {
    opacity: 0; /* Start with opacity 0 */
  }
  100% {
    opacity: 1; /* Bounce with opacity 1 */
  }
}

/* Modal Overlay */
.modal-overlay-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: bounce 0.6s ; 
}

.title-landing-odi{
  color: #f0e7c3!important;
  font-weight: 800!important;
}

.bg-landing-odi{
  background-color: #f0e7c3!important;
}

.lastModal{
  z-index: 9999!important;
}

/* -----------------------------------------------------------end landing oro da investimento------------------------------------------------------------ */

/* -----------------------------------------------------------end landing oro prestige------------------------------------------------------------ */
.iframe-container-op {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio (divides 9 by 16) */
}

.iframe-container-op iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-arrow-op {
  position: absolute;
  top: 30vw;
  left: 0%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: start;
}


@media (min-width: 576px) {
  .overlay-arrow-op {
    position: absolute;
    top: 36vw;
    left: 0%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: start;
  }
}

.overlay-button-op {
  position: relative;
  top: 0;
  width: 100%;
}

.overlay-content-op {
  position: relative;
  padding-top: 2rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-content-header-op {
  position: relative;
  padding-top: 2rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-video-op{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background-color: #0000005b;
}

.overlay-video-header-op{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250vh;
  background-color: #0000005b;
}

.overlay-video-careinlove{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vw;
  background-color: #fcfcfc1b;
}

.bg-yellow-landing-op:disabled {
  background-color: #FFD014;
  color: #fff;
  width: auto !important;
  /* border-radius: 50px; */
  border: none;
  font-weight: 700;
}

.bg-yellow-landing-op {
  background-color: #FFD014;
  color: #fff;
  width: auto !important;
  /* border-radius: 50px; */
  border: none;
  font-weight: 700;
}

.bg-yellow-landing-op:focus,
.bg-yellow-landing-op:hover {
  background-color: #FFD014;
  color: #fff;
  transition: 0.5s;
  transform: scale(1.05);
  width: auto !important;
  /* border-radius: 50px 50px 50px 50px; */
  font-weight: 700;
}

.bg-yellow-landing-op:active {
  background-color: #FFD014;
  color: #fff;
  transition: 0.5s;
  transform: scale(0.99);
  width: auto !important;
  /* border-radius: 50px 50px 50px 50px; */
  border: none;
  font-weight: 700;
}

.arrow-icon-op {
  color: #fff; 
  cursor: pointer;
  animation: bounceUpDown 2s infinite;
}


@media (min-width: 1200px) {

  .overlay-button-op {
    position: absolute;
    top: 45vw;
    left: 10vw;
    width: 100%;
    z-index: 100;
  }

  .overlay-content-op {
    position: absolute;
    top: -8vw;
    left: 0;
    width: 100%;
    height: 56vw;
    background-color: transparent;
  }

  .overlay-arrow-op {
    position: absolute;
    top: 40vw;
    left: 0%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: start;
  }
}

/* -----------------------------------------------------------end landing oro prestige------------------------------------------------------------ */

.arrow-landing-op{
  color: #FFD014!important;
  font-weight: 800!important;
}

.bouncing-button {
  animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1); /* Adjust the scale factor as needed */
  }
}

.glassmorphism{
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(30px);
-webkit-backdrop-filter: blur(30px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

/* faq */

/* The actual popup (appears on top) */
#faq .popuptext {
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
#faq .popuptext:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/*  start animate button for vantaggi section */

/* Hiding the radio icon */
#faq input[type="radio"] {
  display: none;
  color:  rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) ;
}

#faq .tab {
  position: center;
  padding: 0.75rem;
  cursor: pointer;
  z-index: 2;
  height: 54px;
  width: 200px;
  transition: color 0.2s ease-in;
}

#faq .glider {
  position: absolute;
  display: flex;
  left: 1rem;
  height: 54px;
  width: 45%;
  background-color: #fff;
  /* background: white; */
  box-shadow: inset -3px -3px 8px rgba(255, 255, 255, 0.9),
    inset 3px 3px 8px #576179;
  z-index: 1;
  border-radius: 50px;
  transition: transform 0.2s ease-in;
  border: 1px solid rgb(197, 197, 197);
}

#faq input[type="radio"]:checked + label {
  color: rgb(137,118,75);
}

#faq #radio1:checked ~ .glider {
  transform: translateX(0);
}

#faq input[id="radio-2"]:checked ~ .glider {
  transform: translateX(100%);
}

/* unordered list */

#faq .ulSpecial {
  list-style-type: none;
}

#faq .text-animate-gold {
  position: relative;
  background: linear-gradient(90deg, rgba(207,172,72) 0%, rgba(243,220,118) 20%, rgba(207,172,72) 39%, rgba(254,247,182) 50%, rgba(207,172,72) 60%, rgba(243,220,118) 80%, rgba(207,172,72) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;	
  animation: shine 3s infinite;
  background-size: 300%; 
  background-position: left;  
}

@keyframes shine {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

/* arrow pulse */

#faq .down-arrow {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0);
  }
}

#faq .margin-custom-swiper-desktop{
    margin-top: 110px;
    margin-bottom: 50px;
  }


  #faq .margin-custom-swiper-mobile{
  margin-top: 55px;
  margin-bottom: 61px;
}

/* accordion button */

#faq .accordion-button:focus {
  box-shadow: none;
}
#faq .accordion-button::after {
  display: none;
}

#faq .accordion-collapse {
  transition-duration: 0s;
}

#faq .custom-accordion{
  border-radius: 25px 0px 25px 0px!important;
  transition-duration: 0s;
}
#faq .custom-accordion-macro{
  border-radius: 25px 0px 15px 0px!important;
  transition-duration: 0s;
}

#faq .custom-accordion-active{
  border-radius: 25px 0px 0px 0px!important;
  transition-duration: 0s;
}

#faq .custom-accordion-body{
  border-radius: 0px 0px 25px 0px!important;
}

#faq .accordion-item {
  background-color: transparent;
  border: none;
}
  
#faq .custom-img-header-size{
  height: 10rem!important;
}

#faq .custom-accordion .fa-chevron-up {
  transition: transform 0.3s ease-in-out;
}

#faq .custom-accordion.collapsed .fa-chevron-up {
  transition: transform 0.3s ease-in-out;
  transform: rotate(-180deg);
}

#faq .custom-accordion-active .fa-chevron-up{
  transition: transform 0.3s ease-in-out;
  transform: rotate(0deg);
} 

#faq .no-square{
  border-radius: 10px 0px 10px 0px;
  width: 2rem;
  height: 0;
  padding-bottom: 2rem;  
}
#faq .custom-height{
  height: 300px!important;
}

/* accordion button */

#faq .bg-opacity-swiper-home{
  background-color: rgba(255,255,255, 0.85);
}
/* overlay text */
#faq .text-overlay {
  position: absolute;
  top: 47vw;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-shadow: 8px 5px 10px black;
  letter-spacing: 0em;
  transition: letter-spacing 0.5 ease-in-out; 
  animation: stretch 2s ease-in-out forwards;
  font-size: 6vw;
  line-height: 1.1em;
  /* animation-delay: 0.2s; */
}

#faq .pop-up-container{
  position: relative;
}
#faq .text-shadow-none{
  text-shadow: none!important;
}
#faq .text-overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 8px 5px 10px black;
}
@keyframes stretch {
  from {
    letter-spacing: 0.15em;
  }
  to {
    letter-spacing: 0em;
  }
} 


#faq #go-top{
  margin-top: 15vw;
}
@media (min-width: 576px) { 
  #faq #go-top{
    margin-top: 9vh;
  }
  #faq .text-overlay {
    position: absolute;
    top: 35vw;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-shadow: 8px 5px 10px black;
    letter-spacing: 0em;
    transition: letter-spacing 0.5 ease-in-out; 
    animation: stretch 2s ease-in-out forwards;
    font-size: 6vw;
    line-height: 1.1em;
    /* animation-delay: 0.2s; */
  }
  @keyframes stretch {
    from {
      letter-spacing: 0.15em;
    }
    to {
      letter-spacing: 0em;
    }
  } 
}
@media (min-width: 992px) { 
  #faq #go-top{
    margin-top: -15vh;
  }
  #faq .text-overlay {
    position: absolute;
    top: 25vw;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-shadow: 8px 5px 10px black;
    letter-spacing: 00em;
    transition: letter-spacing 0.5 ease-in-out;  
    animation: stretch 2s ease-in-out forwards; 
    font-size: 5vw;
    line-height: 1.1em;
    /* animation-delay: 0.2s; */
  }
  @keyframes stretch {
    from {
      letter-spacing: 0.3em;
    }
    to {
      letter-spacing: 0em;
    }
  }
}
@media (min-width: 1200px) { 
  #faq #go-top{
    margin-top: -15vh;
  }
  #faq .text-overlay {
    position: absolute;
    top: 25vw;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-shadow: 8px 5px 10px black;
    letter-spacing: 00em;
    transition: letter-spacing 0.5 ease-in-out;  
    animation: stretch 2s ease-in-out forwards; 
    font-size: 5vw;
    line-height: 1.1em;
    /* animation-delay: 0.2s; */
  }
  @keyframes stretch {
    from {
      letter-spacing: 0.3em;
    }
    to {
      letter-spacing: 0em;
    }
  }
}


#faq .showCustom{
  display: block!important;
}

#faq .hideCustom{
  display: none;
}

#faq .cta-navbar{
  cursor: pointer;
}

#faq ::marker{
  font-weight: 600;
}

/* In your CSS file */
.formClass {
  width: 20rem!important;
}

@media (min-width: 767.98px) {
  .formClass {
    width: 30rem!important;;
  }
}

@media (min-width: 1199.98px) {
  .formClass {
    width: 40rem!important;;
  }
}
